document.addEventListener("DOMContentLoaded", () => {
  let tabs = document.querySelectorAll(".tabs li");
  let contents = document.querySelectorAll("#tab-content .content");

  const handleClick = (e) => {
    e.preventDefault();

    contents.forEach((node) => {
      node.classList.remove("is-active");
    });

    tabs.forEach((node) => {
      node.classList.remove("is-active");
    });

    let tab = e.currentTarget.dataset.tab;
    let content = document.querySelector('[data-content="' + tab + '"]');
    content.classList.add("is-active");

    e.currentTarget.classList.add("is-active");
  };

  tabs.forEach((node) => {
    node.addEventListener("click", handleClick);
  });

  let start_date = document.getElementById("countdown").dataset.date;
  if (start_date) {
    countdown(start_date);
  }
});

function countdown(dateEnd) {
  let timer, days, hours, minutes;
  let days_l = "dia";
  let hours_l = "hora";
  let minutes_l = "minuto";

  dateEnd = new Date(dateEnd);
  if (isNaN(dateEnd)) {
    return;
  }

  const pluralize = (count, noun, suffix = "s") =>
    `${noun}${count !== 1 ? suffix : ""}`;

  if (calculate()) {
    timer = setInterval(calculate, 1000);
    document.getElementById("countdown").classList.add("active");
  }

  function calculate() {
    let timeRemaining = parseInt((dateEnd - Date.now()) / 1000);

    if (timeRemaining >= 0) {
      days = parseInt(timeRemaining / 86400);
      timeRemaining = timeRemaining % 86400;
      hours = parseInt(timeRemaining / 3600);
      timeRemaining = timeRemaining % 3600;
      minutes = parseInt(timeRemaining / 60);

      document.getElementById("days").innerHTML = parseInt(days, 10);
      document.getElementById("hours").innerHTML = ("0" + hours).slice(-2);
      document.getElementById("minutes").innerHTML = ("0" + minutes).slice(-2);

      document.getElementById("days_l").innerHTML = pluralize(days, days_l);
      document.getElementById("hours_l").innerHTML = pluralize(hours, hours_l);
      document.getElementById("minutes_l").innerHTML = pluralize(
        minutes,
        minutes_l
      );

      return timeRemaining;
    } else {
      return;
    }
  }
}
